(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
function extend( a, b ) {
    'use strict';
    for( var key in b ) {
        if( b.hasOwnProperty( key ) ) {
            a[key] = b[key];
        }
    }
    return a;
}

module.exports = extend;
}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/helpers/extend.js","/helpers")